import Player from "@vimeo/player";

export const  vimeoPlayer = (url, id) => {
    const options = {
        url: url,
        width: 800
    };

    return new Player(id, options);
};
