import "../../shared/global";
import "./index.css";

import { tns } from "tiny-slider/src/tiny-slider";
import Lazyload from "vanilla-lazyload";

import { vimeoPlayer } from "../../shared/vimeo";

let player;

window.addEventListener("DOMContentLoaded", () => {
    const videoEl = document.querySelector(".video .video-wrapper");
    const url = videoEl.getAttribute("data-url");
    const id = videoEl.querySelector(".video-player").getAttribute("id");

    console.log(url, id);
    player = vimeoPlayer(url, id);
});

new tns({
    container: '#home-slider',
    slideBy: 'page',
    controls: false,
    navPosition: 'bottom',
    speed: 500,
    autoplay: true,
    autoplayButtonOutput: false
});

new Lazyload({
    elements_selector: ".lazyload-bg"
});
