import "./global.css";

import LazyLoad from "vanilla-lazyload";

new LazyLoad({
    elements_selector: ".lazyload"
});

const menuToggleEl = document.querySelector(".header-nav .menu-toggle");
const headerMenuEl = document.querySelector(".header-nav .nav-main");

menuToggleEl.addEventListener("click", function (event) {
    event.preventDefault();

    headerMenuEl.classList.toggle("is-open");
});
